export const nn = {
	helloWorld: 'Hallo Verden',
	childTest: 'Velkommen til Dysmate dysleksitest',
	welcomeToTest: 'Velkommen til Dysmate dysleksitest',
	literateChildTest: 'Literate Test',
	youAreReady: 'Du er klar',
	ready: 'Klar',
	chooseRole: 'Velg rolle',
	chooseRoleHelp: 'Velg hvilken rolle denne enheten vil ha',
	chooseLanguage: 'Velg/vel Språk',
	lang_no: `Norsk Bokmål`,
	lang_nn: `Norsk Nynorsk`,
	lang_se: `Svensk`,
	lang_de: `Tysk`,
	lang_nl: `Nederlandsk`,
	lang_en: `Engelsk`,
	blue: 'blå',
	green: 'grøn',
	pink: 'rosa',
	yellow: 'gul',
	red: 'raud',
	violet: 'lilla',
	orange: 'oransje',
	gray: 'grå',
	grey: 'grå',
	bike: 'sykkel',
	teddy: 'bamse',
	apple: 'eple',
	sweater: 'genser',
	thumb: 'tommel',
	unknown: 'unknown',
	testadministrator: 'Testadministrator',
	testcandidate: 'Testkandidat',
	testkey: 'Testnøkkel',
	emailaddress: 'E-post adresse',
	starttest: 'Start test',
	candidateInstructions:
		'Legg inn romnøkkel som vises på testadministrator sin skjerm',
	gototest: 'Gå til testen',
	jumptotest: 'Hopp til test',
	roomkey: 'Romnøkkel: {0}',
	waitforadminstart: 'Vent til testadministrator starter testen',
	done: 'Ferdig',
	name: 'Namn',
	sound: 'Lyd',
	//intro
	helpBox1:
		'For å starte testen må du fylle inn all informasjon under, og deretter kople til ei ekstra eining ved å følge instruksane under.',
	helpBoxList1: 'Opne denne sida på enda ei eining',
	helpBoxList2: 'Vel "Testkandidat"',
	helpBoxList3: 'Oppgi romnøkkel ({0})',
	helpBoxList4: 'Klikk "Gå til testen"',
	helpBoxList5: 'Når dere er klare, trykk "Start test" nedst på denne siden',
	infoAboutCandidate: 'Informasjon om testkandidaten:',
	generalInformation: 'Generell informasjon',
	notaBene: 'NB!',
	candidateReminder:
		'{0} Hugs at det er kandidaten sin informasjon som skal fyllast inn, ikkje din.',
	firstName: 'Fornamn',
	lastName: 'Etternamn',
	birthDate: 'Fødselsdato',
	gender: 'Kjønn',
	pickOne: 'Vel en',
	boy: 'Gut',
	girl: 'Jente',
	unspecified: 'Anna',
	education: 'Utdanning',
	municipality: 'Kommune',
	county: 'Fylke',
	school: 'Skole',
	educationLevel: 'Utdanningsnivå:',
	kindergarten: 'Førskole og 1.klasse',
	primarySchool: 'Barneskole (2. - 7.klasse)',
	juniorHighSchool: 'Ungdomsskole',
	highSchool: 'Videregående skole',
	university: 'Universitet / Høyskole',
	other: 'Anna',
	pickYear: 'Vel år',
	pickClass: 'Vel klasse',
	pickLevel: 'Vel nivå',
	pick: 'Vel',
	lastYearBeforeSchool: 'Siste år før skolestart',
	class: 'Klassetrinn',
	classNum: '{0}. Klasse',
	pickStudyType: 'Vel studieretning',
	specialization: 'Studiespesialisering',
	vocational: 'Yrkesfag',
	bachelor: 'Bachelor',
	master: 'Mastergrad',
	phd: 'Doktorgrad (PhD)',
	specify: 'Spesifiser',
	bilinguagualHomeQuestion: 'Har vakse opp i ein- eller tospråkleg heim?',
	unilingual: 'Ettspråkleg',
	bilingual: 'Tospråkleg',
	multilingual: 'Fleirspråkleg',
	iConsent: 'Eg samtykker',
	consentText:
		'Som testadministrator så bekreftar du at du har nødvendig samtykke frå testkandidaten til å innhente personopplysningar og at desse blir brukte slik det står beskrive i ',
	literatePrivacyPolicy: 'personvernerklæringa til Literate',
	practiceTasks: 'Øvingsoppgåver',
	startPracticeTasks: 'Start øvingsoppgåver',
	listenToInstructions: 'Lytt til instruksjonane.',
	waitingForCandidate: 'Ventar på at kandidaten skal bli klar',
	//Login
	logIn: 'Logg inn',
	loginWithBankId: 'Logg inn med BankID ved å trykke på en av knappene under',
	thisIsForTestAdmins:
		'Dette er for Testadministrator (dvs. sertifiserte brukere med abonnement)',
	bankId: 'BankID',
	bankIdMobile: 'BankID på Mobil',
	pauseBeforeNextTest: '{0} er nå ferdig',
	max2Listens: 'Maks to avspillinger per lydspor',
	timeUsed: 'Tid brukt: {0}',
	start: 'Start',
	pause: 'Pause',
	stop: 'Stopp',
	reset: 'Tilbakestill',
	testIsFinished: 'Testen er nå ferdig!',
	TesFinished_Instruction_ONCANDIATESIDE: 'Testen er nå ferdig!',
	testFinishedInstruction:
		'Svarene er lagret, og du kan nå trygt lukke denne siden eller starte en ny test.',
	closeTest: 'Lukk test',
	//Testadmin:
	hotkeyDescription:
		'Marker rett eller gale ved å klikke på knappane under eller bruk tastatursnarvegane:',
	hotkeyCorrect: 'A: Korrekt',
	hotkeyWrong: 'S: Feil',
	hotkeyButtonCorrect: 'Korrekt (A)',
	hotkeyButtonWrong: 'Feil (S)',
	hotkeyButtonUnanswered: 'Ubesvart (D)',
	taskNum: 'Oppgåve: {0}',
	taskNumOf: 'Oppgåve {0} av {1}',
	correctAnswer: 'Korrekt svar: {0}',
	nextTest: 'Neste test',
	correctAnswerGiven: 'Riktig svar gitt',
	expectedAnswer: 'Forventa svar: {0}',
	clickStartWhenReady: 'Klikk "start testen" når de er klare',
	testDone:
		'{0} er nå ferdig. Klikk på «Neste test» når de er klare til å fortsette.',
	testName_LetterKnowledge: 'Bokstavkunnskap',
	testName_PhonemeIsolation: 'Fonemisolasjon',
	testName_WorkingMemory: 'Arbeidsminne',
	testName_WorkingMemoryNumbers: 'Arbeidsminne: Tal',
	testName_WorkingMemoryWords: 'Arbeidsminne: Ord',
	testName_RapidNaming: 'Hurtignemning',
	testName_PhonemeDeletion: 'Fonemutelatelse',
	testName_OneMinute: 'Ettminuttstest',
	testName_Spoonerism: 'Spoonerism',
	testName_WordDictation: 'Orddiktat',
	testName_TestComplete: 'Testen er Ferdig',
	//LetterKnowledge
	lk_testname: 'Bokstavkunnskapstesten',
	lk_adminIntro1:
		'I denne testen vil kandidaten få opp rekker med bokstaver. Kandidaten sin oppgåve er å lese opp disse bokstavene i rekkefølge. Alt du skal gjøre er å svare om bokstavlyden kandidaten sier stemmer med det som står på din skjerm.',
	lk_admin1:
		'Testkandidaten vil få opp ei rekke bokstavar på skjermen sin som må lesast opp så nøyaktig som mogleg.',
	lk_admin2:
		'Du må følge med på kva slags bokstavar kandidaten les og svare på om han har sagt korrekt eller feil i forhold til korrekt svar som står under her.',
	lk_intro:
		'Du skal nå få sjå nokre bokstavar, og så skal du fortelje meg kva slags bokstavar du kan. Eg vil gjerne vite om du både kan namnet på bokstaven og den lyden som bokstavane seier.',
	//OneMinute
	om_testname: '1-minuttstesten',
	om_admin1:
		'Kandidaten vil få opp rekker med ord på skjermen sin som han må lese opp så raskt og nøyaktig som han greier.',
	om_admin2:
		'Du må følge med på kva ord som kandidaten les og svare riktig eller gale.',
	om_intro:
		'I den neste testen skal du lese så mange ord du greier på eitt minutt.',
	//PhonemeDeletion
	pd_testname: 'Fonemutelatelsestesten',
	pd_admin1:
		'Testkandidaten får lest opp instruksjonar gjennom øvingsoppgåvene. Du skal følge med at riktig svar blir gitt. Dersom svaret er feil må du korrigere kandidaten.',
	pd_admin2:
		'Lytt til testkandidaten og hør om de sier riktig svar som står under. Eleven vil få oppgaver som "Kan du si ris uten /r/". Nedenfor viser vi både det opprinnelige ordet, og det forventede svaret.',
	pd_intro1:
		'I denne testen vil eg be deg seie nokre ord og deretter spørje deg kva slags ord som blir igjen når vi tar bort ein bestemt lyd. For eksempel, om vi tar bort /s/ frå stal, får vi ordet tal.',
	pd_intro2: 'Før testen starter vi med noen øvingsoppgaver.',
	pd_introDone:
		'Veldig bra, du klarte øvingsoppgåvene! Klikk på knappen under når du er klar til å starte testen.',
	pd_testInstruction: 'Lytt til oppgåva og ta bort lyden som instruert',
	//PhonemeIsolation
	pi_testname: 'Fonemisolasjonstesten',
	pi_admin1: 'Hensikta med denne testen er å kartlegge fonembevisstheit',
	pi_admin2:
		'Kandidaten vil få sjå nokre bilde der dei skal klikke på det bildet som viser "ordet som begynner på…" eller "ordet som slutter på ..."',
	candidateAnswersSelf:
		'I denne testen vil testkandidaten svare sjølv på skjermen sin.',
	pi_admin4: 'Vent til kandidaten har utført alle {0} oppgåvene',
	pi_intro1:
		'Du skal nå få sjå nokre bilde, og så skal du peike på det ordet som begynner på, eller sluttar på, ein bestemt lyd',
	pi_intro2: 'Veldig bra, du klarte øvingsoppgåvene!',
	pi_intro3: 'Klikk på knappen under når du er klar til å starte testen.',
	pi_done: 'Fonemisolasjonstesten er ferdig',
	//RapidNaming
	rn_testname: 'Hurtignemningstesten',
	rn_admin1:
		'Denne testen er meint for å måle kor hurtig og nøyaktig kandidaten kan lese opp ei rekke bilde med fargar som kjem opp på skjermen.',
	rn_admin2:
		'I denne testen skal kandidaten få sjå nokre bilde som han skal seie så fort og så nøyaktig som han kan. Vi skal først sjå på fargane som blir brukte, og så skal vi sjå på tinga. Først skal vi gå gjennom nokre øvingsoppgåver, klikk på den blå knappen for å begynne øvingsoppgåvene.',
	rn_admin3:
		'I den første øvingsoppgåva skal kandidaten lese opp nokre fargar som blir viste på skjermen sin. Di oppgåve er å følge med på kva slags fargar som blir lesne opp.',
	rn_adjustIfNotCorrect:
		'Viss desse ikkje stemmer, må du korrigere kandidaten nå. Om alle er rette, kan du gå vidare med knappen under',
	rn_colors: 'Raud - Blå - Grøn - Grå - Oransje',
	rn_objects: 'tommel - eple - sykkel - genser - bamse',
	rn_objectsWithColors:
		'Blå tommel - Oransje Eple - Grøn sykkel - Raud genser - Grå bamse',
	rn_correctColors: 'Korrekte fargar gitt',
	rn_correctItems: 'Korrekte ord gitt',
	rn_admin4:
		'I denne øvingsoppgåva vil kandidaten lese opp nokre ting som blir viste på skjermen sin. Di oppgåve er å følge med at kandidaten les opp riktige ting, i rekkefølge.',
	rn_admin5:
		'Nå skal vi kombinere dei tidlegare oppgåvene, og kandidaten vil bli instruert til å lese opp både fargen og tingen på bilda som blir viste.',
	rn_admin6: 'Du må følge med på kva slags ord og fargar som kandidaten les.',
	rn_admin7:
		'Det var alle øvingsoppgåvene for denne testen. Klikk start testen når de er klare.',
	rn_admin8:
		'Testkandidaten vil få opp ei rekke bilde på skjermen sin som han må lese opp så raskt og nøyaktig som har greier.',
	rn_admin9:
		'Du må følge med på kva fargar og ord som kandidaten les og svare om han har sagt korrekt eller feil ord.',
	rn_timeUsed: 'Tid brukt: {0} sekund',
	rn_intro1:
		'Du skal nå få sjå nokre bilde som du skal seie så fort og så nøyaktig som du kan. Vi skal først sjå på fargane som blir brukte, og så skal vi sjå på tinga. La oss ta nokre øvingsoppgåver.',
	rn_intro2:
		'Høyr godt etter når eg seier namnet på fargane raud – blå – grøn - grå – oransje. Nå er det din tur til å seie fargane så fort du kan.',
	rn_intro3:
		'Høyr nå godt etter når eg seier namnet på tinga tommel – eple – sykkel – genser – bamse. Nå er det din tur til å seie tinga så fort du kan.',
	rn_intro4:
		'Nå skal du få sjå eit bilde med både fargane og tinga. Høyr godt etter når eg seier dei. Nå er det din tur til å seie fargane og tinga så fort du kan.',
	rn_intro5:
		'På det neste bildet vil du få sjå desse fargane og tinga fleire gonger. Du skal seie orda bortover linja, så fort og nøyaktig som du kan, utan å hoppe over nokon. Trykk på startknappen når du er klar',
	//Spoonerism
	sp_testname: 'Spoonerismtesten',
	sp_admin1:
		'I denne testen skal testkandidaten få høre to og to ord, så skal den første lyden byttes i de to ordene sånn at det lages to nye ord eller tulleord. For eksempel, ordene {0}.',
	sp_admin1part2: ``,
	sp_example1: 'Gul og Sol blir Sul og Gol',
	sp_1correct: '1 Korrekt',
	sp_2correct: '2 Korrekt',
	sp_bothWrong: 'Begge feil',
	sp_admin2:
		'Testkandidaten vil få lest opp noen ord som skal leses opp med én lyd byttet mellom seg. Du skal da lytte etter om ordene som blir gjengitt samsvarer med det som står på din skjerm.',
	sp_admin3:
		'Lytt til testkandidaten og hør om de sier riktig ord som det som står på skjermen.',
	sp_instruction:
		'Lytt til oppgaven, og deretter bytt den første lyden i de to ordene',
	sp_intro1:
		'I denne testen skal du få høyre to og to ord, og så skal du byte den første lyden i orda sånn at du lagar to nye ord eller tulleord. For eksempel, orda {0} blir Sul og Gol. Hugs at {1} du skal byte – det er den {2}.',
	sp_intro_reminder: 'det ikkje er bokstaven',
	click_start:
		'Vi er nå ferdig med øvingsoppgavene. Når du nå klikker på start knappen, starter testen. Du skal da løse oppgavene så fort du greier. Jeg vil kun lese opp ordene, så skal du si riktige svar tilbake.',
	//WordDictation
	wd_testname: 'Orddiktattesten',
	wd_intro:
		'Eg skal nå seie nokre ord som du skal skrive. Kvart ord blir presentert i ei setning, og så gjentar eg det som du skal skrive.',
	wd_writeHere: 'Skriv ordet her',
	//WorkingMemory
	ws_testname: 'Arbeidsminne',
	ws_testname_admin: `Arbeidsminne`,
	wsn_testname: 'Arbeidsminne: Tal',
	wsw_testname: 'Arbeidsminne: Ord',
	ws_admin1:
		'Denne testen er meint for å måle arbeidsminnekapasiteten hos kandidaten. ',
	wsn_admin2:
		'Testkandidaten vil få lest opp nokre tal som skal lesast opp i motsett rekkefølge. Du skal da lytte etter om tala som blir attgjevne, samsvarer med det som står på din skjerm.',
	wsw_admin2:
		'Testkandidaten vil få lest opp nokre ord som skal lesast opp i motsett rekkefølge. Du skal da lytte etter om orda som blir attgjevne, samsvarer med det som står på skjermen din.',
	wsw_admin2part2: ``,
	wsn_admin2part2: ``,
	wsn_admin3:
		'Lytt til testkandidaten og høyr om dei seier riktig talrekke som det som står på skjermen.',
	wsw_admin3:
		'Lytt til testkandidaten og høyr om dei seier riktig ordrekke som det som står på skjermen.',
	wsn_intro1:
		'Eg skal nå seie nokre tal til deg. Du må høyre godt etter, for når eg er ferdig, skal du seie dei i omvend rekkefølge enn eg seier dei.',
	wsn_intro2: 'For eksempel, viss eg seier 1 - 3, så skal du seie 3 - 1',
	wsw_intro1:
		'Eg skal nå seie nokre ord til deg. Du må høyre godt etter, for når eg er ferdig, skal du seie dei i omvend rekkefølge enn eg seier dei.',
	wsw_intro2:
		'For eksempel, viss eg seier "bil – ball", skal du seie "ball – bil"',
	wsn_test1: 'Lytt til tala og repeter dei i motsett rekkefølge.',
	wsw_test1: 'Lytt til orda og repeter dei i motsett rekkefølge.',
};

export default nn;
