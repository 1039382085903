import zebrared from '../../../assets/images/rapidNaming/se/zebra-red.png';
import zebrablue from '../../../assets/images/rapidNaming/se/zebra-blue.png';
import zebragrey from '../../../assets/images/rapidNaming/se/zebra-grey.png';
import zebraorange from '../../../assets/images/rapidNaming/se/zebra-orange.png';
import zebragreen from '../../../assets/images/rapidNaming/se/zebra-green.png';

import womanred from '../../../assets/images/rapidNaming/se/woman-red.png';
import womanblue from '../../../assets/images/rapidNaming/se/woman-blue.png';
import womangrey from '../../../assets/images/rapidNaming/se/woman-grey.png';
import womanorange from '../../../assets/images/rapidNaming/se/woman-orange.png';
import womangreen from '../../../assets/images/rapidNaming/se/woman-green.png';


import tablered from '../../../assets/images/rapidNaming/se/table-red.png';
import tableblue from '../../../assets/images/rapidNaming/se/table-blue.png';
import tablegrey from '../../../assets/images/rapidNaming/se/table-grey.png';
import tableorange from '../../../assets/images/rapidNaming/se/table-orange.png';
import tablegreen from '../../../assets/images/rapidNaming/se/table-green.png';


import flowerred from '../../../assets/images/rapidNaming/se/flower-red.png';
import flowerblue from '../../../assets/images/rapidNaming/se/flower-blue.png';
import flowergrey from '../../../assets/images/rapidNaming/se/flower-grey.png';
import flowerorange from '../../../assets/images/rapidNaming/se/flower-orange.png';
import flowergreen from '../../../assets/images/rapidNaming/se/flower-green.png';


import applered from '../../../assets/images/rapidNaming/se/apple-red.png';
import appleblue from '../../../assets/images/rapidNaming/se/apple-blue.png';
import applegrey from '../../../assets/images/rapidNaming/se/apple-grey.png';
import appleorange from '../../../assets/images/rapidNaming/se/apple-orange.png';
import applegreen from '../../../assets/images/rapidNaming/se/apple-green.png';


export const rnImages = [
	[zebrared, womanred, tablered, flowerred, applered],
	[zebrablue, womanblue, tableblue, flowerblue, appleblue],
	[zebragreen, womangreen, tablegreen, flowergreen, applegreen],
	[zebragrey, womangrey, tablegrey, flowergrey, applegrey],
	[zebraorange, womanorange, tableorange, flowerorange, appleorange],
	
];

export const ranIntroItemImages = [
	zebragrey,
	womangrey,
	tablegrey,
	flowergrey,
	applegrey,
];
export const ranIntroImages = [
	flowerblue,
	appleorange,
	zebragreen,
	tablered,
	womangrey,
];

export const rnIntroSteps = [
	'Intro',
	'Colors',
	'Images',
	'Colors and Images',
	'Ready to start',
];

export const rnItems = ['zebra', 'woman', 'table', 'flower', 'apple'];
export const rnColors = ['red', 'blue',  'green','grey', 'orange'];

export const rnAnswers = [
	[
		{color: 0, item: 0},
		{color: 1, item: 1},
		{color: 2, item: 2},
		{color: 0, item: 0},
		{color: 1, item: 3},
		{color: 4, item: 4},
		{color: 4, item: 0},
	],
	[
		{color: 2, item: 4},
		{color: 0, item: 2},
		{color: 3, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
		{color: 4, item: 1},
		{color: 1, item: 4},
	],
	[
		{color: 2, item: 2},
		{color: 0, item: 1},
		{color: 0, item: 3},
		{color: 4, item: 0},
		{color: 2, item: 0},
		{color: 1, item: 3},
		{color: 4, item: 2},
	],
	[
		{color: 3, item: 4},
		{color: 0, item: 3},
		{color: 1, item: 2},
		{color: 4, item: 1},
		{color: 1, item: 4},
		{color: 4, item: 1},
		{color: 3, item: 2},
	],
	[
		{color: 2, item: 4},
		{color: 0, item: 0},
		{color: 3, item: 0},
		{color: 2, item: 3},
		{color: 3, item: 0},
		{color: 2, item: 3},
		{color: 3, item: 4},
	],
	[
		{color: 0, item: 1},
		{color: 4, item: 2},
		{color: 2, item: 1},
		{color: 4, item: 3},
		{color: 3, item: 2},
		{color: 0, item: 3},
		{color: 4, item: 2},
	],
	[
		{color: 2, item: 4},
		{color: 1, item: 1},
		{color: 2, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
		{color: 4, item: 1},
		{color: 0, item: 2},
		{color: 1, item: 1},
	],
];

export const kdrnAnswers = [
	[
		{color: 0, item: 0},
		{color: 1, item: 1},
		{color: 2, item: 2},
		{color: 0, item: 0},
		{color: 1, item: 3},
		{color: 3, item: 4},
	],
	[
		{color: 4, item: 0},
		{color: 2, item: 4},
		{color: 0, item: 2},
		{color: 3, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
	],
	[
		{color: 4, item: 1},
		{color: 1, item: 4},
		{color: 2, item: 2},
		{color: 0, item: 1},
		{color: 0, item: 3},
		{color: 4, item: 0},
	],
	[
		{color: 2, item: 0},
		{color: 1, item: 3},
		{color: 4, item: 2},
		{color: 3, item: 4},
		{color: 0, item: 3},
		{color: 1, item: 2},
		{color: 4, item: 1},
	],
];

export const WMWAnswers = [
	['tjej', 'stol'],
	['hand', 'boll'],
	['pil', 'bär', 'hatt'],
	['buss', 'plåt', 'deg'],
	['väg', 'mål', 'boj', 'kniv'],
	['kopp', 'träd', 'pall', 'mask'],
	['gräs', 'regn', 'mjölk', 'svan', 'tak'],
	['golv', 'säck', 'bok', 'sten', 'grav'],
	['sax', 'häst', 'korv', 'grind', 'sko', 'fisk'],
	['båt', 'får', 'stav', 'hopp', 'grupp', 'hus'],
	['krasch', 'moln', 'blå', 'mark', 'gren', 'vän', 'glass'],
	['svart', 'mås', 'bänk', 'skratt', 'ost', 'hål', 'fot'],
];

export const WMNAnswers = [
	[7, 3],
	[9, 2],
	[8, 5, 1],
	[9, 4, 2],
	[1, 8, 4, 3],
	[3, 7, 2, 6],
	[5, 3, 7, 2, 1],
	[4, 1, 3, 6, 9],
	[1, 9, 5, 2, 7, 8],
	[3, 9, 7, 2, 4, 1],
	[1, 9, 4, 8, 3, 5, 2],
	[2, 7, 4, 1, 7, 9, 6],
];

export const WMNTrialAnswers = [
	[2, 9],
];
export const WMWTrialAnswers = [
	['sol', 'frö'],
];

export const SpoonerismAnswers = [
	{task: 'paket - ryka', correct: 'raket - pyka'},
	{task: 'bok - sång', correct: 'sok - bång'},
	{task: 'katt - hund', correct: 'hatt - kund'},
	{task: 'kran - tång', correct: 'tran - kång'},
	{task: 'slå - kant', correct: 'klå - sant'},
	{task: 'kopp - bord', correct: 'bopp - kord'},
	{task: 'löv - banan', correct: 'böv - lanan'},
	{task: 'skjuta - tand', correct: 'tuta - skjand'},
	{task: 'haj - koka', correct: 'kaj - hoka'},
	{task: 'gata - blomma', correct: 'bata - glomma'},
	{task: 'salt - bönor', correct: 'balt - sönor'},
	{task: 'kanin - måne', correct: 'manin - kåne'},
	{task: 'potta - kotte', correct: 'kotta - potte'},
	{task: 'leopard - fisk', correct: 'feopard - lisk'},
	{task: 'leka - regna', correct: 'reka - legna'},
	{task: 'minne - lunch', correct: 'linne - munch'},
	{task: 'kung - tjat', correct: 'tjung - kat'},
	{task: 'kamp - boll', correct: 'bamp - koll'},
	{task: 'fet - lingon', correct: 'let - fingon'},
	{task: 'resa - bur', correct: 'besa - rur'},
	{task: 'frysa - buske', correct: 'brysa - fuske'},
	{task: 'fem - ring', correct: 'rem - fing'},
	{task: 'tårta - vak', correct: 'vårta - tak'},
	{task: 'diska - lejon', correct: 'liska - dejon'},
	{task: 'glas - sand', correct: 'slas - gand'},
];

export const SpoonerismIntroTasks = [
	{task: 'bil - löv', correct: 'lil - böv'},
	{task: 'fyra - sex', correct: 'syra - fex'},
	{task: 'sjö - rast', correct: 'rö - sjast'},
];

export const phonemeIsolationIntro = [
	{
		id: 0,
		sound: 'g',
		options: {
			a: 'ball',
			b: 'sweater',
			c: 'fish',
			d: 'ski',
		},
		correct: 'b',
	},
	{
		id: 1,
		sound: 'h',
		options: {
			a: 'telephone',
			b: 'flower',
			c: 'motorcycle',
			d: 'house',
		},
		correct: 'd',
	},
];

export const phonemeIsolationTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'c',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'i',
		options: {
			a: 'icecream',
			b: 'chair',
			c: 'baby',
			d: 'horse',
		},
		correct: 'a',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'd',
		type: 'starts_with_',
	},
	{
		id: 5,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'b',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 6,
		options: {
			a: 'cup',
			b: 'chair',
			c: 'horse',
			d: 'book',
		},
		correct: 'a',
		sound: 'p',
		type: 'ends_with_',
	},
];
export const phonemeIsolationNormingTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'book',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'l',
		options: {
			a: 'foot',
			b: 'doll',
			c: 'nose',
			d: 'lion',
		},
		correct: 'lion',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'cat',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'e',
		options: {
			a: 'elephant',
			b: 'spoon',
			c: 'bed',
			d: 'fish',
		},
		correct: 'elephant',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 'i',
		options: {
			a: 'horse',
			b: 'chair',
			c: 'baby',
			d: 'icecream',
		},
		correct: 'icecream',
		type: 'starts_with_',
	},
	{
		id: 5,
		sound: 'v',
		options: {
			a: 'rabbit',
			b: 'gloves',
			c: 'tractor',
			d: 'boot',
		},
		correct: 'gloves',
		type: 'starts_with_',
	},
	{
		id: 6,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'ear',
		type: 'starts_with_',
	},
	{
		id: 7,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'bicycle',
		type: 'starts_with_',
	},
	{
		id: 8,
		sound: 't',
		options: {
			a: 'foot',
			b: 'eye',
			c: 'sun',
			d: 'bed',
		},
		correct: 'foot',
		type: 'ends_with_',
	},
	{
		id: 9,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'shoe',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 10,
		options: {
			a: 'jeans',
			b: 'banana',
			c: 'sweater',
			d: 'saft',
		},
		correct: 'banana',
		sound: 'n',
		type: 'ends_with_',
	},
	{
		id: 11,
		options: {
			a: 'tractor',
			b: 'fish',
			c: 'icecream',
			d: 'cat',
		},
		correct: 'tractor',
		sound: 'r',
		type: 'ends_with_',
	},
	{
		id: 12,
		options: {
			a: 'reflex',
			b: 'plane',
			c: 'book',
			d: 'plowtruck',
		},
		correct: 'plane',
		sound: 'y',
		type: 'ends_with_',
	},
	{
		id: 13,
		options: {
			a: 'chair',
			b: 'cup',
			c: 'horse',
			d: 'book',
		},
		correct: 'cup',
		sound: 'p',
		type: 'ends_with_',
	},
	{
		id: 14,
		options: {
			a: 'bicycle',
			b: 'package',
			c: 'finger',
			d: 'fireengine',
		},
		correct: 'package',
		sound: 'e',
		type: 'ends_with_',
	},
	{
		id: 15,
		options: {a: 'horse', b: 'cup', c: 'porrige', d: 'cow'},
		correct: 'cow',
		sound: 'u',
		type: 'ends_with_',
	},
];

export const oneMinuteTasks = [
	'har',
	'och ',
	'till',
	'den',
	'vill',
	'god',
	'bok',
	'hon',
	'bra',
	'öra',
	'ska',
	'hur',
	'blir',
	'vara ',
	'över',
	'inte',
	'eller',
	'bara',
	'kung',
	'penna ',
	'runt',
	'mellan',
	'kronor',
	'spisen',
	'hjul',
	'göra',
	'skola',
	'svensk',
	'varför',
	'stora',
	'viktig',
	'andra ',
	'flagga',
	'berätta',
	'sig',
	'tidigare',
	'genom',
	'därför',
	'gatsten',
	'samtidig',
	'möjlig',
	'första',
	'fortsatt',
	'varje',
	'alldeles',
	'björn',
	'kjol ',
	'kanske',
	'december',
	'vansklig',
	'linjal',
	'skjorta',
	'kikare',
	'varandra',
	'kölapp',
	'precis',
	'armveck',
	'egentlig',
	'keramik',
	'tiggare',
	'sällskapet',
	'skedde',
	'kyckling',
	'autograf',
	'kista',
	'hjort',
	'kakao',
	'lördag',
	'gjorde',
	'djungel',
	'skäll',
	'utveckling',
	'elefant',
	'ryggsäck',
	'giraff',
	'hjord',
	'gardinerna',
	'leverpastej',
	'rabarber',
	'tuggummi',
	'trampolin',
	'acceptera',
	'proteiner',
	'slickepott',
	'musik',
	'helomvändning',
	'skära',
	'situation',
	'ambivalent',
	'mosaik',
	'erfarenhet',
	'fossil',
	'källsortering',
	'pekfinger',
	'landsförrädare',
	'bilmekaniker',
	'välsigna',
	'perforera',
	'sammansättning',
	'egentligen',
	'spekulation',
	'anklaga',
	'finess',
	'avdramatisera',
	'skärmdump',
	'makulering',
	'garage',
	'ukulele',
	'artär',
	'beredskapsplan',
	'byggindustri',
	'formell',
	'landsbygd',
	'ironisk',
	'utbildningsprogram',
	'beskedligt',
	'varsko',
	'asocial',
	'officiell',
	'hallucination',
	'orubblig',
	'intervention',
	'förbluffad',
	'ointresserad',
	'beivra',
	'flagrant',
	'vaccinera',
	'universell',
	'förskärare',
	'rapsodi',
	'oförsonlig',
	'ordinär',
	'förkrossad',
	'generell',
	'observera',
	'anfäkta',
	'dyskalkyli',
	'opponera',
	'dogmatisk',
	'kulvert',
	'proaktiv',
	'heterogen',
	'preskribera',
	'entré',
	'förtret',
	'illuminerad',
	'irrelevant',
	'desinficera',
	'elicitera',
	'satirisk',
	'konventionell',
	'expedition',
	'paradigm',
	'eklektisk',
	'existentiell',
	'transparent',
	'hegemoni',
	'absorbera',
	'malplacerad',
	'solipsism',
];

export const lkAnswersNorming = [
	'O',
	'O',
	'T',
	'T',
	'W',
	'W',
	'H',
	'H',
	'C',
	'C',
	'E',
	'E',
	'Y',
	'Y',
	'M',
	'M',
	'B',
	'B',
	'Z',
	'Z',
	'Ø',
	'Ø',
	'V',
	'V',
	'K',
	'K',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'P',
	'P',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'Å',
	'Å',
	'Q',
	'Q',
	'U',
	'U',
	'X',
	'X',
	'A',
	'A',
];
export const lkAnswers = [
	'O',
	'O',
	'E',
	'E',
	'I',
	'I',
	'S',
	'S',
	'T',
	'T',
	'U',
	'U',
	'N',
	'N',
	'Å',
	'Å',
	'G',
	'G',
	'D',
	'D',
	'Æ',
	'Æ',
	'Q',
	'Q',
];
export const kdlkAnswers = [
	'H',
	'H',
	'E',
	'E',
	'M',
	'M',
	'B',
	'B',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'U',
	'U',
];
export const pdIntroSteps = [
	{
		task: 'Kan du si "gris"?',
		expectedAnswer: 'gris',
	},
	{
		task: 'Kan du si "gris" uten /g/?',
		expectedAnswer: 'ris',
	},
	{
		task: 'Kan du si "føre"?',
		expectedAnswer: 'føre',
	},
	{
		task: 'Kan du si "føre" uten /f/?',
		expectedAnswer: 'øre',
	},
	{
		task: 'Kan du si "sel"?',
		expectedAnswer: 'sel',
	},
	{
		task: 'Kan du si "sel" uten /l/?',
		expectedAnswer: 'se',
	},
];
export const pdSteps = [
	'gås',
	'mål',
	'brød',
	'klær',
	'brev',
	'trygg',
	'drap',
	'skyll',
	'lås',
	'bro',
	'klatre',
	'malt',
	'bry',
	'lekse',
	'sport',
	'loff',
];
export const pdAnswers = [
	'ås',
	'ål',
	'rød',
	'lær',
	'rev',
	'rygg',
	'rap',
	'sky',
	'lå',
	'bo',
	'klare',
	'mat',
	'by',
	'lese',
	'sort',
	'lo',
];

export const wdAnswersNorming = [
	'mase',
	'fly',
	'dra',
	'kråka',
	'melk',
	'tær',
	'rikke',
	'gråt',
	'bake',
	'regne',
	'hyle',
	'takk',
	'hvordan',
	'verden',
	'ekkelt',
	'magisk',
	'ski',
	'leksefri',
	'historier',
	'flokk',
	'senger',
	'kanskje',
	'sjokolade',
	'hjul',
	'rundt',
	'sild',
	'skjelven',
	'godt',
	'kyllinger',
	'kjøtt',
	'smarttelefon',
	'fugl',
	'sykkelhjelm',
	'hjerte',
	'kjeveortoped',
	'blåbærsyltetøy',
	'biblioteket',
	'kjetting',
	'sannsynlighet',
	'abrakadabra',
];

export const wdAnswers = [
	'hvordan',
	'verden',
	'magisk',
	'ski',
	'leksefri',
	'flokk',
	'kanskje',
	'rundt',
	'kyllinger',
	'blåbærsyltetøy',
	'biblioteket',
	'sannsynlighet',
	'abrakadabra',
];


