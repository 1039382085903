import React, {Component} from 'react';

import wm_number from '../../assets/sounds/de/pauses/FOL018.wav';
import wm_number_se from '../../assets/sounds/se/pauses/goodjob.wav';
import wm_trial_sound from '../../assets/sounds/de/pauses/SCR007.wav';
import wmw_trial_sound_se from '../../assets/sounds/se/pauses/begin_next.wav';
import wmn_trial_sound_se from '../../assets/sounds/se/workingmemory_numbers/se_instruct_numbers.wav'

import wm_words from '../../assets/sounds/de/pauses/FOL035.wav';
import ran from '../../assets/sounds/de/pauses/FOL045.wav';
import Onemint from '../../assets/sounds/de/pauses/FOL047.wav';
import spoonerism from '../../assets/sounds/de/pauses/FOL072.wav';

import AudioPlayer from '../../components/AudioPlayer';
import strings from '../../strings';

class Pause extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.wm_number_sound = wm_number_se;
		this.wm_words_sound = wm_words;
		this.ran_sound = ran;
		this.Onemint_sound = Onemint;
		this.spoonerism_sound = spoonerism;
				break;
			case 'se':
				this.wm_number_sound = wm_number_se;
		this.wm_words_sound = wm_words;
		this.ran_sound = ran;
		this.Onemint_sound = Onemint;
		this.spoonerism_sound = spoonerism;
				break;
			case 'de':
		this.wm_number_sound = wm_number;
		this.wm_words_sound = wm_words;
		this.ran_sound = ran;
		this.Onemint_sound = Onemint;
		this.spoonerism_sound = spoonerism;
				break;
			case 'no':
			default:
				this.wm_number_sound = wm_number_se;
		this.wm_words_sound = wm_words;
		this.ran_sound = ran;
		this.Onemint_sound = Onemint;
		this.spoonerism_sound = spoonerism;
				break;
		}

		

		this.state = {
			testName: this.props.testName ? this.props.testName : '',
			displayBtn: true,
		};
	}

	returnRelevantSound(testName) {
		if(strings.getLanguage()==='de'){
			switch (testName) {
				case strings.wsn_testname:
					if (this.props.pauseSound === 'trial') {
						return wm_trial_sound;
					} else return this.wm_number_sound;
					break;
	
					case strings.wsw_testname:
						if (this.props.pauseSound === 'trial') {
							return wm_trial_sound;
						} else return this.wm_words_sound;
						break;
	
				case strings.rn_testname:
					return this.ran_sound;
					break;
	
				case strings.om_testname:
					return this.Onemint_sound;
					break;
	
				case strings.sp_testname:
					return this.spoonerism_sound;
					break;
			}
		}
		else if(strings.getLanguage()==='se'){
			switch (testName) {
				case strings.wsn_testname:
					if (this.props.pauseSound === 'trial') {
						return wmn_trial_sound_se;
					} else return this.wm_number_sound;
					break;
	
					case strings.wsw_testname:
						if (this.props.pauseSound === 'trial') {
							return wmw_trial_sound_se;
						} else return this.wm_number_sound;;
						break;
	
				case strings.rn_testname:
					return wmw_trial_sound_se;
					break;
	
				case strings.om_testname:
					return wmw_trial_sound_se;
					break;
	
				case strings.sp_testname:
					return wmw_trial_sound_se;
					break;
			}
		}
		
	}

	render() {
		const {testName} = this.state;
		return (
			<div>
				<h2>
					{testName &&  (
						<AudioPlayer
							autoplay
							inline
							src={this.returnRelevantSound(testName)}
						/>
					)}
					{this.props.pauseSound !== 'trial'
						? strings.formatString(
								strings.pauseBeforeNextTest,
								testName
						  )
						: strings.trialIsNowOver}
				</h2>
				{ this.state.displayBtn && (
					<div>
						<button
							onClick={(e) => {
								this.setState({
									displayBtn: false,
								});
							}}
						>
							{strings.start}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default Pause;
