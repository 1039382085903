import {Howl} from 'howler';

import de_trial_tall1 from '../../assets/sounds/de/workingmemory_numbers/wmTrial-numbers-1.wav';
import de_trial_tall2 from '../../assets/sounds/de/workingmemory_numbers/wmTrial-numbers-2.wav';

import se_trial_tall1 from '../../assets/sounds/se/workingmemory_numbers/wmTrial-numbers-1.mp3';


import de_trial_word1 from '../../assets/sounds/de/workingmemory_words/wmTrial-words-1.wav';
import de_trial_word2 from '../../assets/sounds/de/workingmemory_words/wmTrial-words-2.wav';

import se_trial_word1 from '../../assets/sounds/se/workingmemory_words/wmTrial-words-1.mp3';


import no_tall1 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-1.mp3';
import no_tall2 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-2.mp3';
import no_tall3 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-3.mp3';
import no_tall4 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-4.mp3';
import no_tall5 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-5.mp3';
import no_tall6 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-6.mp3';
import no_tall7 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-7.mp3';
import no_tall8 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-8.mp3';
import no_tall9 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-9.mp3';
import no_tall10 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-10.mp3';
import no_tall11 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-11.mp3';
import no_tall12 from '../../assets/sounds/no/workingmemory_numbers/workingmemory-numbers-12.mp3';

import no_word1 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-1.mp3';
import no_word2 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-2.mp3';
import no_word3 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-3.mp3';
import no_word4 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-4.mp3';
import no_word5 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-5.mp3';
import no_word6 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-6.mp3';
import no_word7 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-7.mp3';
import no_word8 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-8.mp3';
import no_word9 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-9.mp3';
import no_word10 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-10.mp3';
import no_word11 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-11.mp3';
import no_word12 from '../../assets/sounds/no/workingmemory_words/workingmemory-words-12.mp3';

import nn_tall1 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-1.mp3';
import nn_tall2 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-2.mp3';
import nn_tall3 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-3.mp3';
import nn_tall4 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-4.mp3';
import nn_tall5 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-5.mp3';
import nn_tall6 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-6.mp3';
import nn_tall7 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-7.mp3';
import nn_tall8 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-8.mp3';
import nn_tall9 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-9.mp3';
import nn_tall10 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-10.mp3';
import nn_tall11 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-11.mp3';
import nn_tall12 from '../../assets/sounds/nn/workingmemory_numbers/workingmemory-numbers-12.mp3';

import nn_word1 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-1.mp3';
import nn_word2 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-2.mp3';
import nn_word3 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-3.mp3';
import nn_word4 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-4.mp3';
import nn_word5 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-5.mp3';
import nn_word6 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-6.mp3';
import nn_word7 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-7.mp3';
import nn_word8 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-8.mp3';
import nn_word9 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-9.mp3';
import nn_word10 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-10.mp3';
import nn_word11 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-11.mp3';
import nn_word12 from '../../assets/sounds/nn/workingmemory_words/workingmemory-words-12.mp3';



import se_word1 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-1.mp3';
import se_word2 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-2.mp3';
import se_word3 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-3.mp3';
import se_word4 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-4.mp3';
import se_word5 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-5.mp3';
import se_word6 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-6.mp3';
import se_word7 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-7.mp3';
import se_word8 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-8.mp3';
import se_word9 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-9.mp3';
import se_word10 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-10.mp3';
import se_word11 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-11.mp3';
import se_word12 from '../../assets/sounds/se/workingmemory_words/workingmemory-words-12.mp3';

import de_tall1 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-1.wav';
import de_tall2 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-2.wav';
import de_tall3 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-3.wav';
import de_tall4 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-4.wav';
import de_tall5 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-5.wav';
import de_tall6 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-6.wav';
import de_tall7 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-7.wav';

import de_tall8 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-8.wav';
import de_tall9 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-9.wav';
import de_tall10 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-10.wav';
import de_tall11 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-11.wav';
import de_tall12 from '../../assets/sounds/de/workingmemory_numbers/workingmemory-numbers-12.wav';

import de_word1 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-1.wav';
import de_word2 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-2.wav';
import de_word3 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-3.wav';
import de_word4 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-4.wav';
import de_word5 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-5.wav';
import de_word6 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-6.wav';
import de_word7 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-7.wav';
import de_word8 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-8.wav';
import de_word9 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-9.wav';
import de_word10 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-10.wav';
import de_word11 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-11.wav';
import de_word12 from '../../assets/sounds/de/workingmemory_words/workingmemory-words-12.wav';

import se_tall1 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-1.mp3';
import se_tall2 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-2.mp3';
import se_tall3 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-3.mp3';
import se_tall4 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-4.mp3';
import se_tall5 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-5.mp3';
import se_tall6 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-6.mp3';
import se_tall7 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-7.mp3';
import se_tall8 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-8.mp3';
import se_tall9 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-9.mp3';
import se_tall10 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-10.mp3';
import se_tall11 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-11.mp3';
import se_tall12 from '../../assets/sounds/se/workingmemory_numbers/workingmemory-numbers-12.mp3';

export const no_numbers = [
	new Howl({src: no_tall1}),
	new Howl({src: no_tall2}),
	new Howl({src: no_tall3}),
	new Howl({src: no_tall4}),
	new Howl({src: no_tall5}),
	new Howl({src: no_tall6}),
	new Howl({src: no_tall7}),
	new Howl({src: no_tall8}),
	new Howl({src: no_tall9}),
	new Howl({src: no_tall10}),
	new Howl({src: no_tall11}),
	new Howl({src: no_tall12}),
];
export const nn_numbers = [
	new Howl({src: nn_tall1}),
	new Howl({src: nn_tall2}),
	new Howl({src: nn_tall3}),
	new Howl({src: nn_tall4}),
	new Howl({src: nn_tall5}),
	new Howl({src: nn_tall6}),
	new Howl({src: nn_tall7}),
	new Howl({src: nn_tall8}),
	new Howl({src: nn_tall9}),
	new Howl({src: nn_tall10}),
	new Howl({src: nn_tall11}),
	new Howl({src: nn_tall12}),
];

export const se_numbers = [
	new Howl({src: se_tall1}),
	new Howl({src: se_tall2}),
	new Howl({src: se_tall3}),
	new Howl({src: se_tall4}),
	new Howl({src: se_tall5}),
	new Howl({src: se_tall6}),
	new Howl({src: se_tall7}),
	new Howl({src: se_tall8}),
	new Howl({src: se_tall9}),
	new Howl({src: se_tall10}),
	new Howl({src: se_tall11}),
	new Howl({src: se_tall12}),
];

export const de_numbers_trial = [
	new Howl({src: de_trial_tall1}),
	new Howl({src: de_trial_tall2}),
];

export const se_numbers_trial = [
	new Howl({src: se_trial_tall1}),
];

export const de_numbers = [
	new Howl({src: de_tall1}),
	new Howl({src: de_tall2}),
	new Howl({src: de_tall3}),
	new Howl({src: de_tall4}),
	new Howl({src: de_tall5}),
	new Howl({src: de_tall6}),
	new Howl({src: de_tall7}),
	new Howl({src: de_tall8}),
	new Howl({src: de_tall9}),
	new Howl({src: de_tall10}),
	new Howl({src: de_tall11}),
	new Howl({src: de_tall12}),
];

export const no_words = [
	new Howl({src: no_word1}),
	new Howl({src: no_word2}),
	new Howl({src: no_word3}),
	new Howl({src: no_word4}),
	new Howl({src: no_word5}),
	new Howl({src: no_word6}),
	new Howl({src: no_word7}),
	new Howl({src: no_word8}),
	new Howl({src: no_word9}),
	new Howl({src: no_word10}),
	new Howl({src: no_word11}),
	new Howl({src: no_word12}),
];
export const nn_words = [
	new Howl({src: nn_word1}),
	new Howl({src: nn_word2}),
	new Howl({src: nn_word3}),
	new Howl({src: nn_word4}),
	new Howl({src: nn_word5}),
	new Howl({src: nn_word6}),
	new Howl({src: nn_word7}),
	new Howl({src: nn_word8}),
	new Howl({src: nn_word9}),
	new Howl({src: nn_word10}),
	new Howl({src: nn_word11}),
	new Howl({src: nn_word12}),
];

export const se_words = [
	new Howl({src: se_word1}),
	new Howl({src: se_word2}),
	new Howl({src: se_word3}),
	new Howl({src: se_word4}),
	new Howl({src: se_word5}),
	new Howl({src: se_word6}),
	new Howl({src: se_word7}),
	new Howl({src: se_word8}),
	new Howl({src: se_word9}),
	new Howl({src: se_word10}),
	new Howl({src: se_word11}),
	new Howl({src: se_word12}),
];

export const de_words_trial = [
	new Howl({src: de_trial_word1}),
	new Howl({src: de_trial_word2}),
];

export const se_words_trial = [
	new Howl({src: se_trial_word1}),
];

export const de_words = [
	new Howl({src: de_word1}),
	new Howl({src: de_word2}),
	new Howl({src: de_word3}),
	new Howl({src: de_word4}),
	new Howl({src: de_word5}),
	new Howl({src: de_word6}),
	new Howl({src: de_word7}),
	new Howl({src: de_word8}),
	new Howl({src: de_word9}),
	new Howl({src: de_word10}),
	new Howl({src: de_word11}),
	new Howl({src: de_word12}),
];
