import React, {Component} from 'react';

const STAGES = {
	INTRO: 0,
	TEST: 1,
	WAITING: 2,
};

class AdminTestPanel extends Component {
	constructor(props, options = {}) {
		super(props);

		this.state = {
			options: {
				stopOnConsecutiveErrors: false,
				maxConsecutiveErrors: 2,
				limitHotkeysToTest: true,
				enableHotkeys: true,
				enableTimers: true,
				...options,
			},
			currentStage: 0,
			currentStep: 0,
			correctAnswers: 0,
			wrongAnswers: 0,
			unanswered: 0,
			consecutiveErrors: 0,
			results: {},
			skipQuestionsOnDeleay:false,
			skipTime:30,
			timeElapsedLasAnsnwer:0,
		};
	}

	componentWillUnmount() {
		document.removeEventListener('keypress', this.handleKeyPress, false);
	}

	componentDidMount() {
		document.addEventListener('keypress', this.handleKeyPress, false);
	}

	componentWillReceiveProps(newProps) {
		const newState = {};
		let flag = false;
		if (newProps.currentStep !== this.props.currentStep) {
			newState.currentStep = newProps.currentStep;
			flag = true;
		}
		if (newProps.currentStage !== this.props.currentStage) {
			newState.currentStage = newProps.currentStage;
			flag = true;
		}
		if (newProps.candidateReady !== this.props.candidateReady) {
			newState.candidateReady = newProps.candidateReady;
			flag = true;
		}
		if (flag) {
			this.setState(newState);
		}
	}

	handleKeyPress = (event) => {
		const {
			options: {limitHotkeysToTest, enableHotkeys},
			currentStage,
		} = this.state;

		if ((!limitHotkeysToTest || currentStage === 1) && enableHotkeys) {
			if (event.key === 'a') {
				this.answerCorrect();
			}
			if (event.key === 'l') {
				this.answerWrong();
			}
			if (event.key === 'k') {
				this.answerUnanswered();
			}
		}
	};

	answerCorrect = (numberOfCorrect = true) => {
		const {results, correctAnswers, currentStep, options} = this.state;

		const newResults = Object.assign({}, results, {
			[currentStep]: {
				isCorrect: numberOfCorrect,
				isAnswered: true,
			},
		});

		this.setState({
			results: newResults,
			correctAnswers: correctAnswers + 1,
			consecutiveErrors: 0,
		});
		if (options.enableTimers) this.props.endTimer();
		this.props.nextStep(newResults); //Making a  change here from state results to new Results to not skip last answer
		if (this.answerCB) this.answerCB();
	};

	answerWrong = () => {
		const {consecutiveErrors, currentStep, options} = this.state;
		const newResults = Object.assign({}, this.state.results, {
			[currentStep]: {
				isCorrect: false,
				isAnswered: true,
			},
		});
		this.setState(
			{
				wrongAnswers: this.state.wrongAnswers + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = options.maxConsecutiveErrors;
				if (
					currErrors >= maxErrors &&
					options.stopOnConsecutiveErrors
				) {
					if (options.enableTimers) this.props.endTimer();
					this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					if (options.enableTimers) this.props.endTimer();
					this.props.nextStep(this.state.results);
				}
			}
		);
		if (this.answerCB) this.answerCB();
	};

	answerUnanswered = () => {
		const {options, consecutiveErrors, currentStep} = this.state;

		const newResults = Object.assign({}, this.state.results, {
			[currentStep]: {
				isCorrect: false,
				isAnswered: false,
			},
		});

		this.setState(
			{
				unanswered: this.state.unanswered + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = options.maxConsecutiveErrors;
				if (
					currErrors >= maxErrors &&
					options.stopOnConsecutiveErrors
				) {
					if (options.enableTimers) this.props.endTimer();
					this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					if (options.enableTimers) this.props.endTimer();
					this.props.nextStep(this.state.results);
				}
			}
		);
		if (this.answerCB) this.answerCB();
	};

	getTestName = () => {
		return null;
	};

	renderIntro = () => {
		return null;
	};
	renderTest = () => {
		return null;
	};
	renderWaiting = () => {
		return null;
	};

	render() {
		const {currentStage} = this.state;

		return (
			<div className="lit-intro">
				<h1 className="lit-page-title">{this.getTestName()}</h1>

				{currentStage === STAGES.INTRO && this.renderIntro()}
				{currentStage === STAGES.TEST && this.renderTest()}
				{currentStage === STAGES.WAITING && this.renderWaiting()}
			</div>
		);
	}
}

export default AdminTestPanel;
